import ReactGA from "react-ga4";

const MEASUREMENT_ID = "G-JC9BTJFJ9K";
ReactGA.initialize(MEASUREMENT_ID);

export const trackPageView = (page) => {
  ReactGA.send({ hitType: "pageview", page });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
