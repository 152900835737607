import React from "react";

const Footer = () => {
  return (
    <footer className="bg-tarotBlue text-white px-4 pt-4 pb-2 text-center">
      &copy; 2024 La Tarot. Все права защищены.
    </footer>
  );
};

export default Footer;
