import React from "react";
import styled from "styled-components";
import { trackEvent } from "../analytics";
import youtubeIcon from "../assets/img/youtube-icon.svg";
import whatsAppIcon from "../assets/img/whatsApp.svg";
import telegramIcon from "../assets/img/telegram.svg";

const Button = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.2),
    5px 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;

  &:hover {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.6),
      -2px 0px 5px rgba(255, 255, 255, 0.7);
  }

  &:active {
    transform: scale(0.95);
  }

  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
`;

export const YouTubeButton = ({ short = false }) => {
  const text = short ? "YouTube" : "Перейти на YouTube канал";
  const handleClick = () => {
    trackEvent("Button Click", "Clicked YouTube Button", "YouTube");
  };

  return (
    <Button
      href="https://www.youtube.com/@la_tarot"
      target="_blank"
      rel="noopener noreferrer"
      className="glossy-button hover-animate hover-animate-1"
      onClick={handleClick}
    >
      <img
        src={youtubeIcon}
        alt="YouTube Channel"
        style={{ width: "24px", height: "24px", marginRight: "8px" }}
      />
      {text}
    </Button>
  );
};

export const WhatsAppButton = () => {
  const handleClick = () => {
    trackEvent("Button Click", "Clicked WhatsApp Button", "WhatsApp");
  };

  return (
    <Button
      href="https://wa.me/13233007533"
      target="_blank"
      rel="noopener noreferrer"
      className="glossy-button hover-animate hover-animate-2"
      onClick={handleClick}
    >
      <img
        src={whatsAppIcon}
        alt="WhatsApp"
        style={{ width: "24px", height: "24px", marginRight: "8px" }}
      />
      WhatsApp
    </Button>
  );
};

export const TelegramButton = () => {
  const handleClick = () => {
    trackEvent("Button Click", "Clicked Telegram Button", "Telegram");
  };

  return (
    <Button
      href="https://t.me/LA_Tarot"
      target="_blank"
      rel="noopener noreferrer"
      className="glossy-button hover-animate hover-animate-3"
      onClick={handleClick}
    >
      <img
        src={telegramIcon}
        alt="Telegram"
        style={{ width: "24px", height: "24px", marginRight: "8px" }}
      />
      Telegram
    </Button>
  );
};
