import React from "react";
import { Button, Box } from "@mui/material";
import { YouTubeButton, WhatsAppButton, TelegramButton } from "./Buttons";
import SectionSeparator from "./SectionSeparator";

const Contact = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      py={0}
      className="flex-col text-center mt-10"
    >
      <SectionSeparator variant={1} />
      <h3 className="text-2xl font-bold mb-4 text-tarotBlue mt-10">
        Каналы для связи
      </h3>
      <p className="text-lg mb-4">
        Не стесняйтесь обращаться по любым вопросам или для записи на прием.
      </p>
      {/* <Button variant="contained" color="primary">
        Связаться
      </Button> */}

      <Box display="flex" justifyContent="center" className="gap-8 flex-wrap">
        {/* <div className="order-last"> */}
        <TelegramButton className="order-2" />
        {/* </div> */}
        <YouTubeButton short={true} />
        <WhatsAppButton />
      </Box>
    </Box>
  );
};

export default Contact;
