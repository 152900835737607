import { createTheme } from "@mui/material/styles";

const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      // This component injects CSS reset styles, perfect for global rules
      styleOverrides: `
        .MuiBox-root {
          padding: 16px;
          border-radius: 4px;
          @media (min-width:900px) {
            padding: 32px;  // Responsive padding for medium and larger screens
          }
        }
      `,
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px", // Set default padding for the CardContent
          "@media (min-width:900px)": {
            padding: "16px 32px", // Responsive padding for medium and larger screens
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "Philosopher, Arial, sans-serif",
    h1: {
      fontFamily: "Philosopher",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Philosopher",
      fontWeight: "bold",
    },
    body1: {
      fontFamily: "Philosopher",
      fontWeight: "normal",
      fontSize: 18,
    },
    body2: {
      fontFamily: "Philosopher",
      fontWeight: "normal",
      fontSize: 16,
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "black",
      paper: "#1a1a2e",
    },
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#805d93",
    },
    info: {
      main: "#8ab6d6",
    },
    text: {
      primary: "#ffffff",
      secondary: "#d8b4e2",
    },
  },
});

export default darkTheme;
