import React from "react";
import styled from "styled-components";
import { CelestialIcon } from "../assets/img/celestial.tsx";
import { CelestialIconTwo } from "../assets/img/celestial-2.tsx";
import { CelestialIconThree } from "../assets/img/celestial-3.tsx";
import { CelestialIconFour } from "../assets/img/celestial-4.tsx";
const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  // margin: 1rem 0;
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(224, 224, 224, 0),
    // rgba(224, 224, 224, 1),
    rgba(224, 224, 224, 1)
  );
`;

const RightLine = styled.div`
  flex: 1;
  height: 1px;
  background: linear-gradient(
    to right,
    rgba(224, 224, 224, 1),
    // rgba(224, 224, 224, 1),
    rgba(224, 224, 224, 0)
  );
`;

const Icon = styled.div`
  padding: 0 1rem;
  color: #a0a0a0;
`;

const SectionSeparator = ({ text, variant = 1 }) => {
  let icon;
  if (variant == 1) {
    icon = <CelestialIcon stroke="white" />;
  } else if (variant == 2) {
    icon = <CelestialIconTwo stroke="white" />;
  } else if (variant == 3) {
    icon = <CelestialIconThree stroke="white" />;
  } else if (variant == 4) {
    icon = <CelestialIconFour stroke="white" />;
  }
  return (
    <SeparatorContainer>
      <Line />
      {icon && (
        <Icon
          style={{
            // flex: 1,
            height: "40px",
            width: "auto",
            // margin: "0 10px",
          }} /* Size icon from parent. Event handlers go here */
        >
          {icon}
        </Icon>
      )}
      {text && <Icon>{text}</Icon>}
      <RightLine />
    </SeparatorContainer>
  );
};

export default SectionSeparator;
