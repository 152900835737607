// src/components/Home.js
import React from "react";
import { Button, Box } from "@mui/material";
import TarotCard from "./TarotCard";
import backgroundImage from "../assets/img/la-tarot-small.WEBP";
import moonImage from "../assets/img/moon.jpg";
import { YouTubeButton, WhatsAppButton, TelegramButton } from "./Buttons";

const Home = () => {
  return (
    <section
      className="text-center bg-cover bg-center min-h-screen flex flex-col justify-center "
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="bg-black bg-opacity-70 p-10 rounded">
        <h2 className="text-4xl font-bold mb-4 text-white">
          Добро пожаловать на сайт LA Tarot
        </h2>
        <p className="text-lg mb-4 text-white">
          Получите свое персональное гадание на таро уже сегодня.
        </p>
        {/* <Button variant="contained" color="primary">
          Записаться на консультацию
        </Button> */}
        <Box display="flex" justifyContent="center" className="gap-8 flex-wrap">
          {/* <div className="order-last"> */}
          <TelegramButton className="order-2" />
          {/* </div> */}
          <YouTubeButton short={true} />
          <WhatsAppButton />
        </Box>
      </div>
      <div className="flex justify-center mt-10">
        {/* <TarotCard
          frontImage={moonImage}
          title="The Moon"
          description="This card represents intuition, dreams, and the unconscious."
        /> */}
      </div>
    </section>
  );
};

export default Home;
