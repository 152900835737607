// src/components/Services.js
import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
// import OracleSVG from "../assets/img/oracle.svg";
import { ReactSVG } from "react-svg";
import { ReactComponent as OracleSVG } from "../assets/img/oracle.svg";
import SectionSeparator from "./SectionSeparator";

const servicesData = [
  {
    title: "КОНСУЛЬТАЦИЯ до 4 вопросов",
    description: `Если у вас есть конкретные вопросы по одной из сфер вашей жизни, такие как работа, отношения, финансы, переезды, здоровье или выбор школы для ребёнка, то этот вид консультации идеально подходит для вас. В ходе консультации я использую карты Таро для детального анализа и предсказаний, которые помогут вам получить ответы и рекомендации по вашим вопросам.

    В данную консультацию входит до 4 ваших вопросов, на которые я дам подробные и развернутые ответы. Мы сможем обсудить любые аспекты, которые вас беспокоят, и найти оптимальные решения для вашей ситуации.

    Консультация проводится в формате голосового сообщения с приложением фото расклада (WhatsApp/Telegram).
    `,
    price: "120$",
    discountedPrice: "75$",
    time: 0,
  },
  {
    title: "ПОДРОБНАЯ КОНСУЛЬТАЦИЯ",
    description: `Если у вас накопилось много разноплановых вопросов, вы хотите получить прогноз на определённый период или тщательно рассмотреть какую-либо сферу вашей жизни, то подробная консультация онлайн будет наиболее подходящим вариантом.

    В ходе консультации мы сможем детально обсудить ваши текущие ситуации и планы на будущее. Это может включать работу, личную жизнь, отношения в паре (не только романтические, но и деловые, дружеские), ваши планы и перспективы проектов, переезды, крупные покупки и другие важные для вас аспекты.

    Вы получите детальные рекомендации о том, над чем стоит работать, что можно улучшить и как справиться с негативными моментами. Консультация включает до 8 ваших вопросов, на которые я предоставлю развернутые и конкретные ответы.

    Консультация проводится в формате видеосвязи (WhatsApp/Telegram), что позволяет нам взаимодействовать в реальном времени и обсудить все важные для вас вопросы.
    `,
    price: "200$",
    discountedPrice: "120$",
    time: 60,
  },
  {
    title: "ДИАГНОСТИКА И чистка",
    description: `Диагностика энергетики и чистка — это процедуры, направленные на выявление и устранение негативных энергетических воздействий на ваше тело и душу.

    В рамках диагностики я провожу детальный анализ вашего энергетического состояния, выявляю блокировки, негативные воздействия и другие факторы, мешающие вашему гармоничному состоянию. Это позволяет не только понять причины вашего дискомфорта, но и выбрать оптимальные методы для их устранения.

    Чистка заключается в снятии негативного энергетического воздействия, очищении ауры и восстановлении энергетического баланса. Этот процесс помогает избавиться от накопившегося негатива, стресса и эмоционального напряжения, возвращая вам чувство легкости и внутреннего покоя.
    `,
    price: "",
    discountedPrice: "50-350$",
    time: 0,
  },
  {
    title: "Консультация в Лос-Анджелесе",
    description: `Консультация в Лос-Анджелесе предлагает уникальную возможность личной встречи для глубокого и всестороннего анализа вашей жизни и ситуации. В этой консультации мы сможем обсудить все ваши вопросы и провести детальный разбор различных аспектов вашей жизни, таких как карьера, отношения, финансы, здоровье и другие важные для вас сферы.

    Личная встреча позволяет создать более тесный контакт и лучше понять вашу энергетику и внутреннее состояние. Мы сможем использовать различные методы, включая чтение карт Таро и эзотерические практики, чтобы предоставить вам максимально точные и полезные рекомендации.
    `,
    price: "300$",
    discountedPrice: "200$",
    time: 60,
  },
];

const Services = () => {
  return (
    <section className="mt-10">
      <SectionSeparator />
      <div className="flex items-center justify-center mt-10 mb-6">
        <h3 className="text-2xl font-bold text-tarotBlue flex items-center">
          <OracleSVG className="mr-2" />
          {"КОНСУЛЬТАЦИИ".toUpperCase()}
        </h3>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center">
        {servicesData.map((service, index) => (
          <Card
            key={index}
            className={` ${
              index === servicesData.length - 1 && servicesData.length % 2 !== 0
                ? "md:col-span-2 justify-center"
                : ""
            }`}
          >
            <CardContent
              className="flex flex-col h-full justify-between cardContentCustom"
              sx={{ padding: "16px !important" }}
            >
              <div>
                <Typography
                  variant="h5"
                  component="div"
                  className="text-tarotBlue text-center"
                >
                  {service.title.toUpperCase()}
                </Typography>

                <Typography
                  variant="body2"
                  className="text-tarotGold whitespace-pre-line w-full pt-2"
                  sx={{ textAlign: { xs: "center" } }}
                >
                  {service.description}
                </Typography>
              </div>
              <div className="mt-4">
                <Typography
                  variant="body2"
                  className="text-tarotGold whitespace-pre-line w-full pb-2"
                  sx={{ textAlign: { xs: "center" }, marginTop: "auto" }}
                >
                  ‌Стоимость: <del>{service.price}</del>{" "}
                  <strong>{service.discountedPrice}</strong>
                  {service.time !== 0 ? " / ~ 60 минут" : null}
                </Typography>
                <SectionSeparator variant={index + 1} />
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Services;
