import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import tarotCardImage from "../assets/img/moon.png";
import { YouTubeButton, WhatsAppButton, TelegramButton } from "./Buttons";
import "./../index.css";

const aboutMeText = `
Меня зовут <span class="name-highlight">Ксения Милановская</span>, и я рада предложить вам глубокие и интуитивные консультации на картах Таро.

Таро — это мощный инструмент, который помогает достать из подсознания то, что мы не осознаем или не понимаем о себе.

С 2002 года я изучаю и практикую психологию, сексологию, эзотерику, духовные и энергетические практики, каббалу. Много лет работала в академии "Женственности" и в <a href="https://federation.formylove.com.ua/our-team/o-tyurina/" target="_blank" rel="noopener noreferrer" class="link-gradient">международной федерации по интимному фитнесу</a>. В настоящее время живу и работаю в Лос-Анджелесе. 

Моя уникальная методика объединяет знания из разных областей, что позволяет предоставлять консультации, которые помогают достигать гармонии и трансформировать вашу жизнь.

Моя цель — помочь вам обрести ясность, понимание и поддержку на жизненном пути. Благодарю вас за доверие к Таро — для меня это волшебный ключ и незаменимый инструмент для улучшения качества жизни!
А для вас?
`;

const IntroductionSection = () => {
  return (
    <Box
      bgcolor="background.paper"
      color="text.primary"
      // p={4}
      // my={4}
      // borderRadius={1}
      boxShadow={3}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <img
            src={tarotCardImage}
            alt="Tarot Card"
            className="w-full h-auto rounded-lg shadow-lg"
            style={{ maxWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h4"
            className="text-tarotGold whitespace-pre-line w-full text-center"
          >
            Приветствую вас на сайте LA Tarot!
          </Typography>
          <Typography
            variant="body1"
            className="text-tarotGold whitespace-pre-line w-full text-center"
            dangerouslySetInnerHTML={{ __html: aboutMeText }}
          />

          <Box
            display="flex"
            justifyContent="center"
            mt={8}
            p={0}
            className="gap-16 flex-wrap"
          >
            <TelegramButton />
            <WhatsAppButton />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            className="gap-4 flex-wrap"
          >
            <YouTubeButton />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default IntroductionSection;
