import React from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import SectionSeparator from "./SectionSeparator";

const faqData = [
  {
    question: "Что такое Таро?",
    answer:
      "Таро — это система карт, каждая из которых имеет свой символизм, значение и энергию. Карты Таро используются для получения инсайтов и рекомендаций, помогающих понять текущую ситуацию и возможные пути развития событий. Это ключ для работы с подсознанием, инструмент для самоанализа и духовного роста.",
  },
  {
    question: "Как работает Таро?",
    answer:
      "Таро работает через синхронизацию с вашей энергетикой и подсознанием. Во время расклада карты Таро отражают ваши внутренние состояния и дают подсказки относительно того, что происходит в вашей жизни. Интерпретация карт требует опыта и интуиции, чтобы точно понять их значение в контексте вашей ситуации.",
  },
  {
    question: "Можно ли предсказать будущее с помощью Таро?",
    answer:
      "Карты Таро не дают точных предсказаний будущего, а скорее предлагают возможные сценарии развития событий. Таро помогает вам лучше понять текущие тенденции и энергии, влияющие на вашу жизнь, чтобы вы могли принять более осознанные решения.",
  },
  {
    question: "Какой подход вы используете в своей работе?",
    answer:
      "Мой подход включает индивидуальное внимание к каждому клиенту, полную конфиденциальность и гибкость в сроках проведения консультаций. Я создаю расклады, основываясь на ваших запросах и учитывая вашу уникальную энергетику.",
  },
  {
    question: "Как обеспечивается конфиденциальность?",
    answer:
      "Конфиденциальность является ключевым аспектом моей работы. Вся информация, предоставленная вами во время консультаций, строго конфиденциальна и не подлежит разглашению. Это является неотъемлемой частью моей профессиональной этики и репутации.",
  },
  {
    question: "Что необходимо для проведения расклада?",
    answer:
      "Для проведения расклада мне понадобятся:\n- Ваши имена и даты рождения (если возможно)\n- Фотографии всех участников расклада\n- Краткое описание вашей ситуации\n- Ваши вопросы и период просмотра\n- Полная предоплата (возможна оплата из любой страны)",
  },
  {
    question: "Как подготовить вопросы для расклада?",
    answer:
      "Не беспокойтесь о формулировке ваших вопросов. Задавайте всё, что для вас важно. Моя задача — переформулировать ваши вопросы, чтобы они были максимально точными и понятными для проведения расклада.",
  },
  {
    question: "Какие вопросы вы не рассматриваете?",
    answer:
      "Я не беру в работу следующие случаи:\n- Беременные женщины\n- Дети до 16 лет (кроме вопросов профориентации, выбора школы или тренера)\n- Вопросы о смерти и поиске пропавших людей, вещей или животных\n- Диагностирование здоровья",
  },
  {
    question: "Как проходит оплата за консультацию?",
    answer:
      "Оплата за консультацию производится заранее. Мы согласовываем дату и тип консультации, вы вносите оплату, после чего я закрепляю за вами время. Обратите внимание, что оплата за консультацию и обрядовую работу не возвращается.",
  },
  {
    question: "Можно ли переносить консультации?",
    answer:
      "Вы можете перенести консультацию один раз, согласовав со мной новое время, не позднее чем за 48 часов до назначенной консультации. Я также оставляю за собой право переноса консультации, прошу отнестись к этому с пониманием.",
  },
  {
    question: "Как проходит консультация?",
    answer:
      "Консультации проводятся онлайн по видеосвязи или через отправку фото расклада и аудио-сообщения, так как я работаю с клиентами по всему миру. Это позволяет вам обращаться к полученной информации в любое удобное для вас время. Личная встреча возможна в Лос-Анджелесе.",
  },
  {
    question: "Могу ли я задавать любые вопросы?",
    answer:
      "Да, не стесняйтесь задавать любые вопросы. Каким бы ни был ваш вопрос или ситуация, вы всегда получите понимание и поддержку. Я гарантирую полную конфиденциальность консультации и всей предоставленной вами информации.",
  },
];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(255, 255, 255, .05)",
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
    margin: 0,
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const FAQSection = () => {
  return (
    <section className="mt-10">
      <SectionSeparator variant={4} />

    <div className="flex items-center justify-center mt-10 mb-6">
      <h3 className="text-2xl font-bold text-tarotBlue flex items-center">
        {/* <OracleSVG className="mr-2" /> */}
        {"Часто задаваемые вопросы".toUpperCase()}
      </h3>
    </div>
    
    <Box
      bgcolor="background.paper"
      color="text.primary"
      // p={4}
      // mb={4}
      // borderRadius={1}
      boxShadow={3}
    >
      {/* <Typography variant="h4" component="h1" gutterBottom align="center">
        Часто задаваемые вопросы
      </Typography> */}
      {faqData.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography
            variant="h6"
            component="h2"
          >
          {faq.question}
          </Typography>
          </AccordionSummary>
          <MuiAccordionDetails>
            <Typography
              variant="body1"
              component="pre"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {faq.answer}
            </Typography>
          </MuiAccordionDetails>
        </Accordion>
      ))}
    </Box>
    </section>
  );
};

export default FAQSection;
